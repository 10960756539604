<template>
  <div>
    <b-row>
      <b-col lg="12" md="12" sm="12" class="heading-title">
       
        <h4>{{ this.permission_access.subname }}</h4>
       
        <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
          <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
          <b-breadcrumb-item active>{{ this.permission_access.subname }}</b-breadcrumb-item>
        </b-breadcrumb>
     
      </b-col>
     <!--  <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col> -->
    </b-row>
    <br />
    <b-card class="mb-4 w-100">
      <div class="venue-heading">
        <b-row>
        <!--   <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <multiselect
                v-model="venue_selected"
                :options="venue_option"
                placeholder="Select Venue"
              >
              </multiselect>
            </div>
          </b-col>
          <b-col
            class="d-flex align-items-stretch "
            cols="12"
            lg="3"
            md="6"
            sm="12"
          >
            <div class="w-100 p-2">
              <div>
                <multiselect
                  v-model="pincode_selected"
                  :options="pincode_option"
                  placeholder="Select Pincode"
                >
                </multiselect>
              </div>
            </div>
          </b-col> -->
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="6"
            md="12"
            sm="12"
          >
            
          </b-col>
        </b-row>
      </div>

      <div>
        <b-row> 
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="4"
            md="6"
            sm="6"
          >
           <!-- <div
              class="
                w-100
                justify-content-sm-center
                justify-content-xl-start
                justify-content-md-start
              "
            >
              <div class="p-0">
                <b-form-select
                  id="select_perpage"
                  v-model="pageSize"
                  :options="options"
                  @change="handlePageSizeChange($event)"
                ></b-form-select>
              </div>
            </div>-->
          </b-col>
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="4"
            md="6"
            sm="6"
          >
          
        </b-col>
          <b-col
            class="d-flex align-items-stretch"
            cols="12"
            lg="4"
            md="12"
            sm="12"
          >
           <!-- <div class="w-100 p-1">
              <b-form-input
                v-model="search"
                id="search"
                type="text"
                placeholder="Search here"
                v-on:keyup="key_search()"
              ></b-form-input>
            </div>-->
            <div class="w-100 d-flex justify-content-end">
                <div class="">
                <b-button class="btn-venuecolor"
                  id="button_width1"
                  variant="primary"
                  @click="CreateParentVanue()"
                v-if="permission_access.vb_create==1"
                >
                  <i class="mdi mdi-plus"></i>Parent Venue</b-button
                >
              </div>
              <!-- <div class="p-2">
                <b-button variant="primary" @click="applyFilter()">
                  Apply</b-button
                >
              </div>
              <div class="p-2">
                <b-button variant="primary" @click="resetFilter()">
                  Reset</b-button
                >
              </div> -->
              <div class="ml-2">
                <b-button class="btn-venuecolor"
                  id="button_width"
                  @click="ExportVanue()"
                  variant="primary"
                 
                >
                  <i class="mdi mdi-export"></i>Export</b-button
                >
              </div>
            
            </div>
          </b-col>
        </b-row>
      </div>
      <br />
      <div>
        <div class="accordion" role="tablist">
        <b-table
          :busy="load"
          :fields="fields"
          :items="venue"
          responsive
          bordered
          show-empty
          empty-text="No Data Available"
          class="text-center"
        >
        
          <template #table-busy>
            <b-skeleton-table
              v-if="load"
              :rows="10"
              :columns="6"
              :hide-header="true"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>
         
        <template #cell(actions)="row">
        <b-button size="sm" @click="row.toggleDetails" >
          <span class="fas fa-eye" v-if="row.detailsShowing==true"></span>
          <span class="fas fa-eye-slash " v-if="row.detailsShowing==false"></span>
          <!-- {{ row.detailsShowing == true ? 'Hide' : 'Show'  }}  -->
        </b-button>
      </template>

      <template #row-details="row">
          <table class="table" >
              <thead class="">
                <tr>
                  <th>Child ID</th>
                  <th>Child Venue Name</th>
                  <th>Category</th>
                  <th>Publish</th>
                  <th>Action</th>
                </tr>
              </thead>
            <tbody>
              <tr v-if="row.item.cvenue.length<=0">
                <td colspan="5">No Child Venue Found</td>
              </tr>
              <tr v-for="(cvn,c) in row.item.cvenue" :key="c">
                <td>{{ cvn.child_auto_no }}</td>
                <td>{{ cvn.child_venue_name }}</td>
                <td>{{ cvn.name }}</td>
                <td> <b-form-checkbox
              name="check-button"
              switch
              v-on:change="statusChangecHILD(cvn.child_venue_id)"
              :checked="cvn.publish_status == 1"
              :disabled="permission_access1.vb_create==0"
            >
            </b-form-checkbox></td>
                <td>
                  <b-link @click="viewVenue(cvn.child_venue_id)" v-if="permission_access1.vb_view==1">
                <i class="fas fa-eye text-dark"></i>
            </b-link>
            &nbsp;
           
             &nbsp;
              <b-link @click="editVenue2(cvn.child_venue_id)" v-if="permission_access1.vb_edit== 1 ">
              <i class="fas fa-edit text-primary"></i>
            </b-link> 
                </td>
              </tr>
            </tbody>
            
        
        </table>
      </template>
          
          <template #cell(venue_publish)="data">
            <b-form-checkbox
              name="check-button"
              switch
              v-on:change="statusChange(data.item.id)"
              :checked="data.item.venue_publish == 1"
            >
            </b-form-checkbox>
          </template>
          <template #cell(action)="data">
            <router-link :to="'/venues/view-parent-venue/' + data.item.id" v-if="permission_access.vb_view== 1">
              <i class="fas fa-eye fa-lg text-dark"></i>
            </router-link>
            &nbsp;
            <router-link :to="'/venues/edit-parent-venue/' + data.item.id"  v-if="permission_access.vb_edit== 1">
              <i class="fas fa-edit text-primary"></i>
            </router-link>
          </template>
           <template #cell(child_venue)="data">
            <router-link :to="'venues/child-venue/' + data.item.id"  >
              <i class="fas far fa-list-alt"></i>
            </router-link>
          </template> 
         
        </b-table>
        </div>
        <b-row align-v="center">
          <b-col cols="4">
            <span
              >Showing <b>{{ totalRows }}</b> out of
              <b>{{ totalData }}</b> entries.</span
            >
          </b-col>
          <b-col cols="8">
            <b-pagination
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              @change="handlePageChange"
              prev-text="Prev"
              next-text="Next"
              align="right"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
//import Multiselect from "vue-multiselect";
export default {
  name: "managevenues",
//"subname": "Manage Venues",
  data: () => ({
    title: "Manage Venues",
    title1: "Child venue",
    num: 1,
    // Month Table
    fields: [
      //  'index',
      { key: 'actions', label: 'Child Venue' },
      {
        key: "parent_auto_no",
        label: "Parent ID",
        sortable: true,
      },
      {
        key: "parent_auto_no",
        label: "Child view",
        sortable: true,
      },  {
        key: "venue_name",
        label: "Venue Name",
        sortable: true,
      },
      {
        key: "venue_city",
        label: "City",
        sortable: true,
      },
      {
        key: "venue_pincode",
        label: "Pincode",
        sortable: true,
      },
      {
        key: "venue_publish",
        label: "Publish",
      },{
        label: "Action",
        key: "action",
      },
    
      /* {
        key: "child_venue",
        label: "Child Venue",
      }, */
    ],
    item: [],
    permission_access: '',
    permission_access1: '',
    value: "",
    venue_selected: "",
    pincode_selected: "",
    venue: [],
    venueCounts: 0,
    options: [10, 20, 30, 50],

    pageSize: 10,
    show: false,
    load: true,
    filter: "",
    search: "",
    CurrentPage: 1,
    sortBy: "created_at",

    page: 1,
    count: 0,
    totalData: "",
    totalRows: "",

    PerPage: "50",

    pincode_option: [],
    venue_option: [],
  }),
  components: { //Multiselect 
  },
  methods: {
    CreateParentVanue() {
      this.$router.push("/venues/add-parent-venue");
    },
    handlePageChange(value) {
      this.page = value;
      this.load = true;
      this.venuelListing();
    },
    handlePageSizeChange(event) {
      // console.log(event.target.value);
      this.pageSize = event;
      this.load = true;
      this.page = 1;
      this.venuelListing();
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      this.load = true;
      this.venuelListing();
    },
    venuelListing() {
      this.load = true;
      // this.venue = [];
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post("/admin/parent_venue_fetch", {
          params,
          search_keyword: keyword,
          venue_pincode: this.pincode_selected,
          venue_name: this.venue_selected,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            this.venue = [];
            if (resp.data.status) {
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.parent_venues.length;
              var show ;
              for (var i = 0; i < resp.data.parent_venues.length; i++) {
                
if(i==0)
{
  show =true;
}
else
{
  show =false;
}
                this.venue.push({
                  id: resp.data.parent_venues[i].parent_venue_id,
                  parent_auto_no: resp.data.parent_venues[i].parent_auto_no,
                  venue_name: resp.data.parent_venues[i].venue_name,
                  venue_city: resp.data.parent_venues[i].venue_city,
                  venue_pincode: resp.data.parent_venues[i].venue_pincode,
                  venue_publish: resp.data.parent_venues[i].publish_status,
                  cvenue: resp.data.parent_venues[i].cvenue,
                  _showDetails: show,
                });
              }
              this.show = false;
              this.load = false;
            } else {
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.parent_venues.length;

              this.show = false;
              this.load = false;
            }
          }
        });
    },
    getRequestParams(page, pageSize, sortBy = "venue_name") {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      params["sortBy"] = sortBy;
      return params;
    },
    venuePincodeListing() {
      axios.get("/api/venue_pincode_listing").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.pincode_option = resp.data.venue_pincodes;
            this.venue_option = resp.data.venue_names;
          }
        }
      });
    },
    applyFilter() {
      const value = "1";
      this.handlePageChange(value);
    },
    resetFilter() {
      this.pincode_selected = "";
      this.venue_selected = "";
      this.search = "";
      this.venuelListing();
    },
    statusChange(venue_id) {
      axios.post("/api/publish_status", { venue_id: venue_id }).then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.$root.$refs.app.showToast("success", resp.data.message);
            //this.venuelListing();
          } else {
            this.$root.$refs.app.showToast("danger", resp.data.message);
          }
        }
      });
    },
    ExportVanue() {
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post(
          "/api/parent_pdf_export",
          {
            params,
            search_keyword: keyword,
            venue_pincode: this.pincode_selected,
            venue_name: this.venue_selected,
          },
          {
            responseType: "blob",
          }
        )
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "venue_list.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
    key_search() {
      const value = "1";
      this.handlePageChange(value);
    },

    /* Child venue */
    editVenue(id)
    {
      this.$router.push("/venues/edit-child-venue/" + id);
    },
    editVenue2(id)
    {
      this.$router.push("/venues/new_edit_child_venue/index/" + id);
    },
    editVenue1()
    {
      const id = this.$route.params.id;
      this.$router.push("/venues/Child_venue/index/" + id);
    },
     viewVenue(id)
    {
      this.$router.push("/venues/view-child-venue/" + id);
    },
    CreateChildVanue() {
      const id = this.$route.params.id;
      this.$router.push("/venues/add-child-venue/" + id);
    },

    statusChangecHILD(venue_id) {
      axios
        .post("/api/child_publish_status", { child_venue_id: venue_id })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.$root.$refs.app.showToast("success", resp.data.message);
              //this.venuelListing();
            } else {
              this.$root.$refs.app.showToast("danger", resp.data.message);
            }
          }
        });
    },
    access_permission()
			{
				axios
					.post("/api/access_permission_all").then((resp) => {
						for(var i=0; i< resp.data.length;i++)
             {
              if(resp.data[i].subname==this.title )
              {
                this.permission_access= resp.data[i];
              }
              if(resp.data[i].subname==this.title1 )
              {
                this.permission_access1= resp.data[i];
              }
             
             }
					});
			},
  },

  mounted() {
    this.access_permission();
    this.venuelListing();
    this.venuePincodeListing();
    this.show = true;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2962ff;
    background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%);
}
/* .b-table-has-details, .b-table-details
{
  cursor: pointer;
    background: whitesmoke;
} */
.table-head-color
{
  background: #A44BF3;
  color:white;
}
</style>
